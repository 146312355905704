// Angular material

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$labfinder-angular-material-primary: mat.define-palette(mat.$deep-purple-palette, 700);
$labfinder-angular-material-accent: mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$labfinder-angular-material-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$labfinder-angular-material-theme: mat.define-light-theme((
  color: (
    primary: $labfinder-angular-material-primary,
    accent: $labfinder-angular-material-accent,
    warn: $labfinder-angular-material-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($labfinder-angular-material-theme);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@import '~@angular/cdk/overlay-prebuilt.css';
@include mat.core();

// END: Angular material

@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '../src/assets/css/progressbar.css';
@import '../src/assets/css/variables';

@font-face {font-family: 'MontHeavy';src: url('assets/fonts/MontHeavy.eot');src: url('assets/fonts/MontHeavy.eot') format('embedded-opentype'),url('assets/fonts/MontHeavy.woff2') format('woff2'),url('assets/fonts/MontHeavy.woff') format('woff'),url('assets/fonts/MontHeavy.ttf') format('truetype'),url('assets/fonts/MontHeavy.svg#MontHeavy') format('svg');}
// @import 'ngx-toastr/toastr';


.text-green {color: var(--green) !important;}
input,select,textarea,button {box-shadow: none !important;}
/*****Buttons*******/
.btn {padding: 10px 20px;font-size: 14px;font-style: normal;font-weight: 500;display: flex;align-items: center;-webkit-border-radius: 60px;-moz-border-radius: 60px;border-radius: 60px;}
.btn-red-fill {background: var(--primary-color);color: var(--white-color);}
.btn-white-fill {background: var(--white-color);color: var(--grey-color);}
.btn-white-fill:hover,
.btn-red-fill:hover {background: var(--blue-color);color: var(--white-color);}
.btn {box-shadow: none !important;    justify-content: center;} 
.btn-green-border {color: var(--green);border: solid 1px var(--green);}
.btn-green-border:hover {background: var(--green);color: var(--white-color);}
.btn-red-border {color: var(--primary-color);border: solid 1px var(--primary-color);}
.btn-red-border:hover {background: var(--primary-color);color: var(--white-color);}

.btn-warning-border { border-color: #ff9800;
  color: orange;}
.btn-warning-border:hover {background: orange;color: var(--white-color);}
.input-group .ng-select-container {
  border-right: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none !important;
}

.btn-grey {color: rgba(138, 138, 138, 1);border: solid 1px rgba(138, 138, 138, 1);}
.btn-blue-border {color: var(--blue-color);border: solid 1px var(--blue-color);}
.btn-blue-border:hover {background: var(--blue-color);color: var(--white-color);}
.btn-grey-border {color: #666;border: solid 1px #d3d3d3;}
.btn-grey-border:hover {background: #d3d3d3;color: var(--blue-color);}
.call_24 {color: var(--blue-color);font-size: 15px;font-style: normal;font-weight: 400;line-height: normal;padding: 10px 30px;border: 1px solid #84A4CE;-webkit-border-radius: 52px;-moz-border-radius: 52px;border-radius: 52px;}
.call_24:hover {background-color: #84A4CE;color: var(--blue-color);}
.form_buttons {z-index:+9999 !important; position: fixed;left: 0;bottom: 0;width: 100%;border: 1px solid #F4F4F4;background: #FFF;box-shadow: 0px -6px 54px 0px rgba(0, 0, 0, 0.05);padding: 25px 0;}
.form_buttons .btn {padding: 12px 40px;font-size: 16px;width: 388px;margin: 0 12px;max-width: 100%;justify-content: center;}
/*****Margins and Padding*******/
.mb-10 {margin-bottom: 10px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-40 {margin-bottom: 40px !important;}
.mb-50 {margin-bottom: 50px !important;}
.mb-60 {margin-bottom: 60px !important;}
.mt-10 {margin-top: 10px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-40 {margin-top: 40px !important;}
.mt-50 {margin-top: 50px !important;}
.mt-60 {margin-top: 60px !important;}
.mb-25 {margin-bottom: 25px !important;}
/*****Global Font*******/
*, body {font-family: 'Roboto', sans-serif;}
/*****Custom Container Size*******/
@media(min-width: 1440px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {max-width: 1200px;}
}
@media(min-width: 1600px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {max-width: 1460px;}
}
.relative {position: relative;}
/*****Hero*******/
.hero {z-index: 0;position: relative;}
.carousel-caption {right: 0;bottom: auto;left: 0;z-index: 10;padding-top: 0;padding-bottom: 0;color: var(--white-color);text-align: left;width: 100%;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);}
.hero-text {width: 560px;max-width: 560px;}
.hero-text h2 {font-size:30px;font-style: normal;font-weight: 700;}
.carousel-indicators li {width: 12px;height: 12px;margin: 0 6px;background-color: var(--white-color);transition: opacity .6s ease;-webkit-border-radius: 100%;-moz-border-radius: 100%;border-radius: 100%;border: 2px solid var(--white-color);}
.carousel-indicators .active {border: 2px solid var(--white-color);background-color: var(--primary-color);}
.carousel {width: 100%;}
.slide1-layer {width: 100%;height: 100%;position: absolute;left: 0;top: 0;background: var(--blue-dark);opacity: 0.4;}
.slide2-text,
.slide3-text {-webkit-border-radius: 30px;-moz-border-radius: 30px;border-radius: 30px;background: rgba(255, 255, 255, 0.3);padding: 30px;width: 640px;}
.slide2-text h2,.slide2-text h4 {color: var(--blue-dark);}
.slide2-text ul,
.slide3-text ul {margin: 0 0 30px 0;padding: 0;display: flex;flex-wrap: wrap;}
.slide2-text ul li,
.slide3-text ul li {padding: 0 0 0 44px;background: url(assets/images/list-icon.svg) no-repeat 0 3px;list-style: none;color: var(--blue-dark);font-size:26px;font-style: normal;font-weight: 500;line-height: 105%;letter-spacing: 0.15px;text-transform: capitalize;margin: 0 0 30px;width: 100%;}
.slide3-text ul li {color: var(--white-color);}
.slide3-text {background: rgba(45, 37, 30, 0.5);}
/*****products-category*******/
.products-category {padding:45px 0;}
.cate-block {width: 20%;padding: 0 15px;}
.cate-block img,.cate-block .cate-background-image{cursor: pointer; width: 100%;-webkit-border-radius: 8px;-moz-border-radius: 8px;border-radius: 8px;-moz-transition: all 0.3s;-webkit-transition: all 0.3s;transition: all 0.3s;}
.cate-block:hover img,.cate-block:hover .cate-background-image {
-moz-transform: scale(1.1);-webkit-transform: scale(1.1);transform: scale(1.1);}
.cate-img {margin-bottom: 10px;overflow: hidden;position: relative;-webkit-border-radius: 8px;-moz-border-radius: 8px;border-radius: 8px;border: 1px solid #ddd;}
.cate-block p {margin: 0;color: #3B3B3B;font-size: 16px;font-style: normal;font-weight: 500;line-height: 139.187%;     text-align: left !important;}
/*****products-videos*******/
.products-videos {padding-bottom:45px;}
.products-videos .row {margin: 0 -20px;}
.products-videos .col-md-4 {padding: 0 20px;flex: 0 0 25%; max-width: 25%; margin-bottom: 20px;}
.videos-img {position: relative;width: 100%;-webkit-border-top-left-radius: 8px;-webkit-border-top-right-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-topright: 8px;border-top-left-radius: 8px;border-top-right-radius: 8px;overflow: hidden;margin-bottom: 20px;}
.videos-img::before {width: 100%;height: 100%;position: absolute;z-index: 1;left: 0;top: 0;content: '';background: rgba(0, 0, 0, 0.3);}
.videos-img img {width: 100%;-webkit-border-top-left-radius: 8px;-webkit-border-top-right-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-topright: 8px;border-top-left-radius: 8px;border-top-right-radius: 8px;-moz-transition: all 0.3s;-webkit-transition: all 0.3s;transition: all 0.3s;}
.videos-img video, .videos-img iframe {    display: flex; width: 100%;-webkit-border-top-left-radius: 8px;-webkit-border-top-right-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-topright: 8px;border-top-left-radius: 8px;border-top-right-radius: 8px;-moz-transition: all 0.3s;-webkit-transition: all 0.3s;transition: all 0.3s;     height: 184px;object-fit: cover;width: auto;max-width: inherit;min-width: 100%;}
.videos-img:hover img {
-moz-transform: scale(1.1);-webkit-transform: scale(1.1);transform: scale(1.1);}
.videos-img:hover video, .videos-img:hover iframe {
-moz-transform: scale(1.1);-webkit-transform: scale(1.1);transform: scale(1.1);}
.products-videos .col-md-4 h4 {  white-space: nowrap;overflow: hidden;text-overflow: ellipsis; color: #3B3B3B;font-size: 16px;font-style: normal;font-weight: 500;line-height: 139.187%; margin: 0 0 12px;}
.products-videos p {color: #656565;font-size: 14px;font-style: normal;font-weight: 400;line-height: 139.187%;margin: 0 0 20px;}
.products-videos .btn {width: 108px;justify-content: center;padding: 10px 0;font-size: 16px;}
.play-btn {width: 70px;height: 70px;position: absolute;left: 50%;top: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);background: url(assets/images/play.svg) no-repeat center;z-index: 2;background-size: 100%;cursor: pointer;;}
.video-block {width: 100%;position: relative;padding-bottom: 55%;}
.video-block iframe, .video-block video {width: 100%;height: 100%;position: absolute;left: 0;top: 0;}
/*****achievements*******/
.achievements {background-color: #002F6C;padding:25px 0; text-align: center;}
.achievements h2 {color: var(--white-color);font-size: 25px;font-style: normal;font-weight: 500;line-height: 128.688%;margin: 0 0 20px;}
.achievements h2:after {content: "";width: 50px;height: 4px;background: var(--primary-color);margin: auto;display: block;margin-top: 7px;}
.achievements h4 {color:#fff;font-size: 44px;font-style: normal;font-weight:700;line-height: 128.688%;margin: 0 0 3px;}
.achievements h6 {color:#d4cece;font-size: 16px;font-style: normal;font-weight: 400;line-height: 120%;margin: 0;}
/*****solution*******/
.solution {padding:45px 0 55px 0;overflow: hidden;background: #0C1A35;}
.solution .section-heading {color: var(--white-color);}
.section-sub-heading {color: var(--primary-color);font-size:25px;font-style: normal;font-weight: 600;line-height: 130.687%;margin: 0 0 6px;}
.products-category-slider .section-sub-heading {margin: 0 0 20px;}

.section-heading {color: var(--blue-dark);font-size: 16px;font-style: normal;font-weight: 700;line-height: 123.688%;margin: 0 0 20px;}
.section-heading a {color: var(--primary-color);}
.round-10 {-webkit-border-radius: 10px;-moz-border-radius: 10px;border-radius: 10px;}
.solution h6 {color: var(--primary-color);font-size: 14px;font-style: normal;font-weight: 400;line-height: 130.687%;margin: 0 0 11px;}
.solution h3 {color: var(--white-color);font-size:24px;font-style: normal;font-weight: 500;line-height: 35.5px;letter-spacing: 0.9px;margin: 0 0 23px;}
.solution p {color: #7F90B0;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;margin: 0;}
.solution .row {margin: 0 -45px;}
.solution .col-md-4 {padding: 0 45px;}
.solution .col-md-4:last-child {padding-left: 0;}
/*****popular-products*******/

.popular-products .slick-initialized .slick-slide {
  display: flex;
  height: inherit !important;
  border: solid 1px #EBEBEB;
  border-radius: 10px;
}
.popular-products .product-box {
  border: none;
  width: 100%;
  border-radius: 0;
}
.popular-products .slick-track {
  display: flex !important;
}
product-card{width: 100%;}

.popular-products {padding:45px 0;}
.popular-products+.popular-products {padding-top: 0;}
.View-all-products .btn {padding: 12px 100px;font-size: 16px;}
.devider {width: 100%;height: 1px;background: #D9D9D9;margin: 0 0 35px 0;}
.product-box {border: solid 1px #EBEBEB;width: 100%;-webkit-border-radius: 10px;-moz-border-radius: 10px;border-radius: 10px;}
.products-row product-card{border: solid 1px #EBEBEB;width: 100%;-webkit-border-radius: 10px;-moz-border-radius: 10px;border-radius: 10px; display: flex; height: 100%;}
.products-row .product-box{border:none; -webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0;}
.product-img img {-webkit-border-top-left-radius: 10px;-webkit-border-top-right-radius: 10px;-moz-border-radius-topleft: 10px;-moz-border-radius-topright: 10px;border-top-left-radius: 10px;border-top-right-radius: 10px;}
.product-text {padding: 20px;}
.reviews {font-family: var(--poppins);font-size: 12px;font-style: normal;font-weight: 400;line-height: 21px;}
.product-text h4 {color: var(--blue-dark);font-size: 16px;font-style: normal;font-weight: 500;line-height: 139.187%;margin: 0 0 6px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.product-text h4 a {color: var(--blue-dark);text-decoration: none;}
.product-text .btn {padding: 6px 30px;font-size: 14px;}
.price {color: var(--blue-dark);font-size: 18px;font-style: normal;font-weight: 500;line-height: 24px;letter-spacing: 0.54px;}
.product-price {margin-bottom: 15px;}
.taxes {color: var(--yellow);font-size: 12px;font-style: normal;font-weight: 400;line-height: 21px;}
.taxes br {display: none;}
.product-qty h6 {color: #8A8A8A;font-size: 14px;font-style: normal;font-weight: 400;line-height: 139.187%;margin: 0 0 5px;}
.product-qty h6:last-child {margin: 0;}
.product-qty h6 strong {color: #3B3B3B;font-weight: 400;}
.qty-right {width: 86px;height: 34px;border: solid 1px #D0D0D0;-webkit-border-radius: 100px;-moz-border-radius: 100px;border-radius: 100px;}
.qty-right .btn-number {width: 15px;height: 15px;padding: 0;text-align: center;justify-content: center;font-weight: 300;}
.qty-right .input-number {height: 32px;border: none;box-shadow: none;color: #3B3B3B;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;padding: 0;text-align: center;}
.product-qty{flex-wrap: wrap; justify-content: end !important;}
.qty-left{width: 100%; margin-bottom: 10px;}
.slick-slide {margin: 0 15px;}
.slick-list {margin: 0 -15px;}
.leftbar .slick-list {margin: 0;}
.leftbar .slick-slide {margin: 0;}
.slick-prev,
.slick-next {
width: 40px;height: 40px;background-color: #F5F5F5 !important;-webkit-border-radius: 100px;-moz-border-radius: 100px;border-radius: 100px;background-repeat: no-repeat !important;background-position: center !important;background-size: 24px !important;z-index: 9;margin-top: -40px;}
.slick-prev {left: -23px;background-image: url(assets/images/prev.svg) !important;}
.slick-next {right: -23px;background-image: url(assets/images/next.svg) !important;}
.slick-prev::before,
.slick-next::before {display: none !important;}
/*****service-offerings*******/
.service-offerings {padding:45px 0;background: #EBEFF5;overflow: hidden;}
.service-offerings .slick-prev,
.service-offerings .slick-next, .products-videos .slick-prev, .products-videos .slick-next {margin-top: 0;top: -36px;}
.service-offerings .slick-prev, .products-videos .slick-prev {left: auto;right: 50px;background: url(assets/images/prev1.svg) no-repeat center !important; -webkit-border-radius: 0px; border-radius: 0px;}
.service-offerings .slick-next, .products-videos .slick-next {right: 0px;background: url(assets/images/next1.svg) no-repeat center !important; -webkit-border-radius: 0px; border-radius: 0px;}
.service-offerings .btn {padding: 6px 0;font-size: 13px;width: 100px;justify-content: center;}
.service-offerings .cate-block {width:100%;padding:0;}

.products-category-slider {padding:45px 0;background: #fff;overflow: hidden;}
.products-category-slider .slick-prev,
.products-category-slider .slick-next {margin-top: 0;top: -36px;}
.products-category-slider .slick-prev {left: auto;right: 50px;background: url(assets/images/prev1.svg) no-repeat center !important; -webkit-border-radius: 0px; border-radius: 0px;}
.products-category-slider .slick-next {right: 0px;background: url(assets/images/next1.svg) no-repeat center !important; -webkit-border-radius: 0px; border-radius: 0px;}
.products-category-slider .btn {padding: 6px 0;font-size: 13px;width: 100px;justify-content: center;}
.products-category-slider .cate-block {width:100%;padding:0;}
.cate-background-image {width: 100%;padding-bottom: 74%;background-size: cover;background-position: center;}
.service-box {-webkit-border-radius: 08px;-moz-border-radius: 08px;border-radius: 08px;display: flex;flex-wrap: wrap;background: var(--white-color);overflow: hidden;}
.service-text {width: calc(100% - 213px);padding: 25px;}
.service-img {width: 213px;}
.service-img img {-webkit-border-top-left-radius: 8px;-webkit-border-bottom-left-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-bottomleft: 8px;border-top-left-radius: 8px;border-bottom-left-radius: 8px;height: 100%;object-fit: cover;width: auto;max-width: inherit;}
.service-box h4 {color: #112242;font-size: 25px;font-style: normal;font-weight: 600;line-height: 125%;margin: 0 0 10px;}
.service-box p {color: #666;font-size: 15px;font-style: normal;font-weight: 400;line-height: 147.687%;margin: 0 0 15px;}
// .service-offerings .container {padding-left: calc((100vw - 1430px)/2);width: 100%;max-width: 100%;padding-right: 0;}
/*****The problem*******/
.problem-left {width: 50%;background-size: cover;}
.problem-right {width: 50%;background-color: #0c1a35;background-image: url(assets/images/the-problem-bg.png) !important;background-repeat: no-repeat !important;background-position: 0 center !important;padding: 60px 60px 60px 100px;}
.problem-right .section-heading {color: #fff;}
.problem-text {width: 600px;max-width: 100%;}
.problem-text ul {margin: 0;padding: 0;}
.problem-text ul li {list-style: none;display: flex;flex-wrap: wrap;}
.problem-text ul li+li {margin-top: 60px;}
.problem-number-col {color: #677692;font-family: 'MontHeavy';font-size: 60px;font-style: normal;line-height: 128.688%;width: 74px;}
.problem-text-col {width: calc(100% - 74px);padding-left: 40px;padding-top: 10px;}
.problem-text-col h6 {color: var(--primary-color);font-size: 14px;font-style: normal;font-weight: 400;line-height: 130.687%;margin: 0 0 13px;}
.problem-text-col p {color: var(--white-color);font-size: 20px;font-style: normal;font-weight: 500;line-height: 30px;letter-spacing: 0.6px;margin: 0;}
/*****The problem*******/
.features {border-bottom: solid 1px #EEE;padding: 40px 0;}
.feature-icon {width: 40px; margin-top: 3px;}
.feature-text {
  // width: calc(100% - 60px);
  padding-left: 20px;
}
.feature-text h4 {color: var(--black-color);font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;margin: 0 0 6px;}
.feature-text h6 {margin: 0;color: #292929;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;}
.heading-section {padding:30px 0;}
.page-content {padding: 60px 0 20px;}
body {overflow-x: hidden;}
.login-content{overflow: hidden;}
@media (min-width: 768px) {
  body{    zoom:88%;}
.no-header-footer{zoom:100%;}
.right-form{
  zoom: 90%;
}
}

@media (max-width: 767px) {
  body{    zoom:90%;}
  }
/*****Modal*******/
.modal {padding: 0 !important;}
.modal-header {padding: 0;border: none;}
.modal-header .close {font-weight: 300;border: solid 1px #8A8A8A;width: 30px;height: 30px;padding: 0;margin: 0;position: absolute;right: 10px;top: 10px;border-radius: 20px;z-index: 2;display: flex;align-items: center;justify-content: center;font-size: 18px;    outline: none !important;background: #fff;opacity: 1 !important;}
.product-text .btn-green-border,.product-text .btn-red-border {padding: 6px 8px; min-width: 100px;justify-content: center;}
.modal-body h3{font-size: 30px;font-weight: 700;color: #112242;}
/*****Responsive*******/
@media (max-width: 1430px) {
.service-offerings .container {padding-left: 15px;}
.products-category-slider .container {padding-left: 15px;}
.product-text .btn-green-border,.product-text .btn-red-border {padding: 6px 8px; min-width: 100px;justify-content: center;}
.slick-prev {left: 0;}
.slick-next {right: 0;}
}
@media (max-width: 1199px) {
.service-offerings .container {padding-right: 15px;}
.products-category-slider .container {padding-right: 15px;}
.achievements h4 {font-size: 30px;}
.nav-tabs .nav-link {font-size: 26px;padding: 20px;}
.nav-tabs .nav-link.active:before {top: 28px;}
.solution .col-md-4 {padding: 0 20px;}
.solution .row {margin: 0 -20px;}
.hero-text h2 {font-size: 34px;}
.hero-text .mb-60 {margin-bottom: 30px;}
.slide2-text ul li,.slide3-text ul li {padding: 0 0 0 30px;font-size: 18px;line-height: 24px;margin: 0 0 10px;background-size: 20px auto;}
.slide2-text ul,.slide3-text ul {margin: 0 0 10px 0;}
.hero-text h2.mb-40 {margin-bottom: 20px !important;}
.hero-text h2.mb-60 {margin-bottom: 20px !important;line-height: 1.4;}
.slide2-text,.slide3-text {width: 550px;}
}

@media (max-width: 991px) {
.problem-left {width: 100%;height: 50vw;}
.problem-right {width: 100%;padding: 40px;}
.problem-text ul li+li {margin-top: 30px;}
.achievements h4 {font-size: 30px;}
.hide-md {display: none;}
.carousel-item img {object-fit: cover;width: auto;max-width: inherit;min-height: inherit;}
.service-img {width: 100%;}
.service-text {width: 100%;padding: 25px;}
.service-img img {-webkit-border-top-left-radius: 8px;-webkit-border-top-right-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-topright: 8px;border-top-left-radius: 8px;border-top-right-radius: 8px;border-top-left-radius: 0;border-bottom-left-radius: 0;height: auto;object-fit: inherit;width: 100%;max-width: 100%;}
.cate-block {width: 33.333%;margin-bottom: 30px;}
.products-videos .row {margin: 0 -15px;}
.products-videos .col-md-4 {padding: 0 15px;}
}
@media (min-width: 768px) {
.features .col-md-3{flex: 0 0 20%;max-width: 20%;}
}

@media (max-width: 767px) {
.features {padding-top: 10px;}
.features .col-sm-6 {margin-top: 30px;}
.problem-right {padding: 30px;}
.problem-text-col {width: calc(100% - 74px);padding-left: 20px;}
.problem-text-col p {font-size: 16px;line-height: 26px;}
.section-heading {font-size:18px;}
.achievements .col {flex-basis: inherit;flex-grow: inherit;max-width: 100%;width: 50%;margin-top: 30px;}
.achievements h2 {margin: 0;}
.achievements h6 {font-size: 14px;}
.solution .col-md-4+.col-md-4 {margin-top: 30px;}
.nav-tabs .nav-item a {margin-right: 0;}
.solution .col-md-4:last-child {padding-left: 20px;}
.solution h3 {font-size: 26px;line-height: 30px;}
.hero-text h2 {font-size: 20px;}
.hero-text h5 {font-size: 16px;}

.hero-text .btn {padding: 10px 20px;font-size: 12px;}
.hero-text .mb-60 {margin-bottom: 15px;}
// .carousel-item img {object-fit: cover;width: auto;max-width: inherit;min-height: 300px;}
.carousel-item {min-height: 300px !important; }
.service-offerings .slick-next {right: 0;}
.service-offerings .slick-prev {right: 50px;}
.products-category-slider .slick-next {right: 0;}
.products-category-slider .slick-prev {right: 50px;}
.products-category {padding-bottom: 30px;}
.slide2-text,.slide3-text {padding: 15px;}
.products-videos .col-md-4{    flex: 0 0 100%;max-width: 100%;}
.products-videos .col-md-4+.col-md-4 {margin-top: 30px;}
.videos-img video {height: 50vw;}
.product-img img {
  height:43vw !important;
  width: auto !important;
  margin: auto;
  min-width: 100%;
}
.hero-text { width: auto;max-width: 100%; }
}
@media (max-width: 479px) {
.service-offerings .section-heading {font-size: 25px;}
.products-category-slider .section-heading {font-size: 25px;}
.cate-block {width: 50%;}
}







// footer {background: #F3F6FA;}
// .footer-top {padding: 40px 0 40px;}
// .footer-bottom {padding: 30px 0;border-top: solid 1px #ececec;}
// .footer-top ul {margin: 0;padding: 0;justify-content: end;}
// .footer-top ul li {color: var(--blue-color);font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;list-style: none;margin-left: 65px;}
// .footer-top ul li a {color: var(--blue-color);text-decoration: none !important;}
// .footer-top ul li a:hover {color: var(--primary-color);}
// .footer-bottom p {color: var(--blue-color);font-size: 12px;font-style: normal;font-weight: 400;line-height: 24px;margin: 0;}
// .social a {width: 30px;height: 30px;text-align: center;line-height: 30px;color: var(--blue-color);text-decoration: none !important;border: solid 1px var(--blue-color);-webkit-border-radius: 100px;-moz-border-radius: 100px;border-radius: 100px;margin-left: 20px;}
// .social a:hover {color: var(--primary-color);border-color: var(--primary-color);}
// .logo-img{width: auto;}

// @media (max-width: 1199px) {
// .footer-top ul li {margin-left: 25px;}
// }
// @media (max-width: 767px) {
// .footer-top,.footer-bottom {text-align: center;}
// .footer-top .justify-content-end,.footer-bottom .justify-content-end {justify-content: center !important;}
// .footer-top ul li {margin: 10px 10px 0;}
// .footer-top ul {margin-top: 20px;justify-content: center;}
// .social {margin-top: 10px;}
// .social a {margin: 0 10px;}
// }

.form_buttons{
    z-index: 6;
}


.ng-value-container {
    height: 48px!important;
    border-color: #D0D0D0!important;
    border-radius: 4px!important;
    letter-spacing: 0.15px!important;
    color: #8A8A8A!important;
    font-size: 12px!important;
    // background: #fff url(/assets/images/select_arrow.svg) no-repeat right center!important;
    background-size: 25px auto!important;
}

a.nav-link.disabled,a.dropdown-item.disabled {
    opacity: 0.5;
}

.loaders{
    text-align: center;
}

.invalid-feedback { display: block !important;position: absolute; }
.requiredFiled{
  color: #dc3545;
    font-size: 80%;
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
      }
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .progress:after { border: none !important; border-radius: 0px !important; }
  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    overflow: auto;
    pointer-events: auto;  
  }

  // .cursor-pointer { cursor: pointer;
  //   position: absolute;
  //   right: 8px;
  //   top: 12px;
  // }
  .ri-star-s-fill.active {
    color: #ffad33 !important; /* Change the color to represent an active star */

  }
  .cursor-pointer { cursor: pointer;
 
  }
  // .count{
  //   margin-right: 32px;
  // }

  .requiredText{
    margin-top: -6px;
  }
  .modal-backdrop { z-index: 9999 !important;     width: 100%;
    height: 100%; }

    .modal {
      z-index: 999999!important;
    }
.cdk-global-overlay-wrapper {height: auto;width: auto;left: 50%;top: 50%;transform: translate(-50%, -50%);}

.dropdown-item.active {color: var(--primary-color) !important;text-decoration: none !important;background-color: #f8f9fa !important;}
.thumb-slide .skeleton-loader.circle.progress {min-height: 138px !important;}
@media (max-width: 767px) {
  .thumb-slide .skeleton-loader.circle.progress {min-height: 60px !important;}
  }
  @media (max-width: 479px) {
  .thumb-slide img {min-height: 60px;}
  }

  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.spinner-border{ color: var(--loder-color); }
.slick-track { min-width: 100%;}
.carousel + .justify-content-between.mt-2{margin-top:40px !important;}

.service-row service-card{width: 100%;}

.divclass{
  height: 500px; 
  overflow-y: scroll;
}
.nonDiv{
  height: 200px; 
  overflow-y: scroll;
}
.norecord{
  display: flex;
  justify-content:space-around;
  font-weight: inherit;
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px !important;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgba(0,0,0,0.8);
}
.no-arrow .ng-select .ng-arrow-wrapper {
  display: none;
}
.ri-heart-fill { color: #9D0808 !important; }

.btn-option{    display: inline-block;}
.query_block{height: 20px;}
.query-button{
  position: relative;
}

.queryPopup{
  background-color: white;
  padding: 15px;
  position: absolute;
  right:-20px;
  top: 18px;
  display:none;
  box-shadow: 0px 13px 16px 0px rgba(233, 236, 240,);
  z-index: 2;
}
.query_block:hover .queryPopup{ display: block;}
.toggled {
  display: block;

}

.queryPopup li{
  list-style: none;
    cursor: pointer;
    color: var(--blue-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
    padding: 10px;
    border-top: solid 1px #F0EEEE;
    white-space: nowrap;
}

.queryPopup li:first-child{
  border: none;
}

.queryPopup li:hover{
  color: var(--primary-color);
}

.contents{
  overflow: hidden;
  width: 171px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-decoration: underline;
}
.form-control:disabled, .form-control[readonly] {
  background-color: inherit;
}

.MsoNormalTable{width: 100%;}
.MsoNormalTable td{padding:7px;  white-space: normal;}
.MsoNormalTable td p{margin: 0;}

.slick-dots li button:before {
  font-size: 12px !important;
}



.rdio {
  position: relative;
  margin-right: 15px;
}
.rdio input[type=radio] {
  opacity: 0;
}
.rdio label {
  padding-left:30px;
  cursor: pointer;
  margin-bottom: 7px !important;
  color: #2A2B2F;
font-size: 14px;
font-weight: 400;
line-height: 20px;
}
.rdio label:before {
  width:20px;
  height:20px;
  position: absolute;
  top: 2px;
  left: 0;
  content: "";
  display: inline-block;
  // -moz-border-radius: 50px;
  // -webkit-border-radius: 50px;
  // border-radius: 50px;
  // border: 1px solid #8A8A8A;
  // background: #fff;
  background: url(assets/images/radio.svg) no-repeat center;
  background-size: 100%;
}
.rdio input[type=radio] {
  margin: 0px;
}
.rdio input[type=radio]:disabled + label {
  color: #999;
}
.rdio input[type=radio]:disabled + label:before {
  // background-color: #BA0C2F;
}
.rdio input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  top:2px;
  left:0;
  display: inline-block;
  font-size: 11px;
  width:20px;
  height:20px;
  background: url(assets/images/radio-active.svg) no-repeat center;
  background-size: 100%;
}

.rdio-default input[type=radio]:checked + label:before {
  border-color: #BA0C2F;
}

.rdio-primary input[type=radio]:checked + label:before {
  border-color: #BA0C2F;
}
.rdio-primary input[type=radio]:checked + label::after {
  // background-color: #BA0C2F;
}

.query-text a:hover{text-decoration: none;}

@media (max-width:767px) {
  .btn-option {position: absolute; top: 10px;right: 10px;}
  .query-button {position: static;}
  .query{ position: relative; padding-right: 30px !important;}
}
app-header{position: relative;z-index: 10;}
  .form-control,.ng-select .ng-select-container,label.file-label .file-custom{border-color: #8A8A8A !important;color: rgba(0,0,0,0.8) !important;}
  .form-control:focus,.ng-input:focus,.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container{border-color:var(--blue-color) !important;color:var(--blue-color) !important;}
  .form-control:focus+i{color:var(--blue-color) !important;}
  input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(0,0,0,0.8) !important;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba(0,0,0,0.8) !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(0,0,0,0.8) !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(0,0,0,0.8) !important;
}

.ngx-toastr {
  width: fit-content !important;  // Specify toastr-dialog width for all devices
  max-width: 60vw !important;
}
.ngx-toastr {
  @media (max-width: 768px) {
    width: fit-content !important;  //  Specify toastr-dialog width only on small devices 
    max-width: 85vw !important;
  }
}

a { cursor: pointer; }
.label-small { z-index: 5; color: #8A8A8A;font-family: Roboto;font-size: 10px;font-style: normal;font-weight: 400;line-height: 140%;letter-spacing: 0.125px;position: absolute;left: 10px;top: -6px;background: #fff;padding: 0 5px;}
.form-group{position: relative;}

.register_form .tab-content{
  min-height: 50vh;
}
@media (min-width: 2500px) {
.register_form .tab-content{
  min-height: 70vh;
}
}



@media (min-width: 992px){
    .page-content{min-height: calc(112vh - 297px);}

  }

  .intrest-areas .ng-select-disabled .ng-arrow-wrapper,.tooltip.show {
    display: none !important;
}


.ri-information-line {
  position: relative;
  cursor: pointer;
  &:after {
      content:attr(data-original-title) attr(title);
      position: absolute;
      bottom:95%;
      left: -30px;
      background:#000;
      z-index: +99;
      color: #fff;
      font-size: 12px;
      border-radius: 3px;
      padding: 5px 10px;
      line-height:18px;
      font-family: "Roboto", sans-serif;
      width: 200px;
      text-align:left;
      white-space: normal;
      visibility: hidden;
      opacity:0; transition:all 0.3ms ease-in-out;
  }
  &:hover:after{ visibility:visible;opacity:1;}
 
}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{z-index: +1;}
@media (max-width: 576px){
  .ri-information-line {
    &:after {
      left: auto;
      right: 0px;
    }
    &.ri-information-left:after{ left: 0px;
      right:auto;}
  }
  .rdio label {
    font-size: 13px;
}
}


.btn-outline-primary:hover {
  background-color: var(--blue-color);
  border-color: var(--blue-color);
}
.btn-outline-primary {
  color:var(--blue-color);
  border-color:var(--blue-color);
}
a.certificates {
  color: var(--primary-color) !important;
}
// router-outlet-height
.router-outlet-height {
  height: 100%;
  min-height:80vh;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.primary-text{ color: var(--primary-color) !important; }
.secondary-text { color: var(--blue-dark) !important; }

.cdk-overlay-container {
  z-index: 99999;
}
.cdk-overlay-pane {
  max-width: 90vw !important;
}
.add-address-button-container{padding-left: 5px !important;}

.sticky-footer{        padding-bottom: 100px;}