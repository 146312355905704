:root{
    --primary-color:#BA0C2F;
    --white-color: #fff;
    --offWhite-color: #F4EFF0;
    --black-color: #000;
    --grey-color: #3B3B3B;
    --blue-color: #002F6C;
    --green: #389D51;
    --blue-dark: #112242;
    --yellow: #FFAD33;
    --poppins: 'Poppins', sans-serif;
    --loder-color: #F3F6FA;
}